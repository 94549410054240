import React, { FC, memo, useCallback } from 'react'
import { Pagination, Spin } from 'antd'
import styles from './style.module.scss'
import NewsTab from './components/NewsTab'
import NewsItem from './components/NewsItem'
import NewsHead from './components/NewsHead'
import useFetchList from './hooks/useFetchList'

const News: FC = () => {
  const {
    data: newsList,
    page: pageInfo,
    loading,
    changeQueryParams,
  } = useFetchList()

  const handlePageChange = useCallback(
    (page: number, pageSize: number | undefined): void => {
      changeQueryParams({
        page,
        size: pageSize,
      })
    },
    [changeQueryParams]
  )

  return (
    <>
      <NewsHead />
      <NewsTab />
      <Spin spinning={loading}>
        <div className={styles.NewsContent}>
          <div className={styles.NewsItemWrap}>
            {!!newsList.length &&
              newsList.map((item) => <NewsItem key={item.id} {...item} />)}
            {!!newsList.length && (
              <div className={styles.PaginationWrap}>
                <Pagination
                  onChange={handlePageChange}
                  hideOnSinglePage
                  defaultCurrent={1}
                  total={pageInfo.totalCount}
                />
              </div>
            )}
          </div>
        </div>
      </Spin>
    </>
  )
}

export default memo(News)
