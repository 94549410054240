import React, { memo } from 'react'
import Banner from '@/components/Banner'
import ProductIntro from './components/ProductIntro'
import styles from './style.module.scss'

function Product() {
  const bannerList = [
    {
      tabTitle: '英雄杀小游戏',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/product/yxs.png',
      mobileUrl:
        'https://tc-cdn.123u.com/official-website/mobile/product/yxs.png',
      content: (
        <div className={`${styles.Description}`}>
          <div className={styles.Title}>策略卡牌，公平竞技</div>
          <div className={styles.SubTitle}>
            英雄杀小游戏是一款结合了历史人物特色的竞技卡牌游戏。在英雄杀小游戏里，玩家通过选择不同的角色，根据不同角色的技能组合搭配随机拿到的手牌进行战斗，并根据场上的状况及时变更对战策略，才能赢得最终的胜利。
          </div>
          {/* <div onClick={() => window.open('https://yxsm.qq.com/')} className={styles.DetailBtn}>查看详情</div> */}
        </div>
      ),
    },
    {
      tabTitle: '街机三国',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/product/jjsg_v2.jpg',
      mobileUrl:
        'https://tc-cdn.123u.com/official-website/mobile/product/jjsg.png',
      content: (
        <div className={`${styles.Description}`}>
          <div className={styles.Title}>唤醒街机格斗的激情</div>
          <div className={styles.SubTitle}>
            游戏中以《三国志》为时代背景，玩家扮演的角色穿越到三国时代，成为天选之子，还原了原著中的人物派系和历史战场，为玩家提供7个职业，并有个人争霸，军团争霸，三国争霸等多项赛事活动。
          </div>
          <div
            onClick={() => window.open('http://sg.huanle.com/')}
            className={styles.DetailBtn}
          >
            查看详情
          </div>
        </div>
      ),
    },
    {
      tabTitle: '美食大战老鼠',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/product/ms.png',
      mobileUrl:
        'https://tc-cdn.123u.com/official-website/mobile/product/ms.png',
      content: (
        <div className={`${styles.Description}`}>
          <div className={styles.Title}>十年易逝，经典不老</div>
          <div className={styles.SubTitle}>
            《美食大战老鼠》是一款极富策略性的网页休闲游戏。贪婪的老鼠结队前来掠夺美食，唯一的防御方式就是培育美食进行反击。游戏集成了角色成长、即时战略、塔防战和卡片收集等要素，乐趣十足。
          </div>
          <div
            onClick={() => window.open('http://my.4399.com/yxmsdzls/')}
            className={styles.DetailBtn}
          >
            查看详情
          </div>
        </div>
      ),
    },
    {
      tabTitle: '龙之谷手游',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/product/dn-banner.png',
      mobileUrl:
        'https://tc-cdn.123u.com/official-website/product/dn-banner-small.png',
      content: (
        <div className={`${styles.Description}`}>
          <div className={styles.Title}>无差别，真竞技</div>
          <div className={styles.SubTitle}>
            龙之谷是一款3D无锁定动作手游，具有酣畅自由的战斗体验，原汁原味的经典形象，刻骨铭心的泪点剧情等特点。龙之谷手游力求100%还原端游特色，将精彩绝伦的战斗于掌中呈现，让玩家的指尖踏上非同凡响的阿尔特里亚之旅。
          </div>
        </div>
      ),
    },
  ]

  const productLists = [
    {
      img: 'https://tc-cdn.123u.com/official-website/product/yxs-ck.png',
      title: '英雄杀',
      coverImg:
        'https://tc-cdn.123u.com/official-website/product/yxs-qr-10-03.png',
      subTitle: '策略卡牌，公平竞技',
      url: 'https://yxsm.qq.com/',
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/product/jjsg-ck_v2.png',
      title: '街机三国',
      subTitle: '唤醒街机格斗的激情',
      url: 'http://sg.huanle.com/',
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/product/ms-ck.png',
      title: '美食大战老鼠',
      subTitle: '十年易逝，经典不老',
      url: 'http://my.4399.com/yxmsdzls/',
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/product/dn-ck.png',
      title: '龙之谷手游',
      subTitle: '无差别，真竞技',
      url: '',
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/product/ro-ck_v2.png',
      title: 'RO爱如初见',
      subTitle: '还原情怀，超越经典',
      url: '',
    },
    {
      img: 'https://tc-cdn.123u.com/official-website/product/more-ck.png',
      title: '即将上线',
      subTitle: '更多精彩游戏，敬请期待',
      url: '',
      closed: true,
    },
  ]

  return (
    <>
      <Banner bannerList={bannerList} autoplay showBottomTabs loop showArrow />
      <div className={styles.ImageWrap}>
        <img src="https://tc-cdn.123u.com/official-website/product/ourgames.png" />
        <div className={styles.OurGame}>
          <div className={styles.Title}>OUR GAMES</div>
          <div className={styles.SubTitle}>让更多人发现人生的美好</div>
        </div>
      </div>
      <div id="product-card">
        <ProductIntro productLists={productLists} />
      </div>
    </>
  )
}

export default memo(Product)
