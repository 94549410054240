import React, { useCallback } from 'react'
import Banner from '@/components/Banner'
import styles from './style.module.scss'

function SocialRecruit() {
  const bannerList = [
    {
      tabTitle: '英雄杀小游戏',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/recruit/banner.jpg',
      mobileUrl:
        'https://tc-cdn.123u.com/official-website/mobile/recruit/header.png',
    },
  ]
  const hireList = [
    {
      name: '技术',
      number: 20,
      url: 'https://huanle.jobs.feishu.cn/index?category=6791702736615426317',
    },
    {
      name: '策划',
      number: 15,
      url: 'https://huanle.jobs.feishu.cn/index?category=6791702736615409933',
    },
    {
      name: '美术',
      number: 25,
      url: 'https://huanle.jobs.feishu.cn/index?category=6791702736615344397',
    },
    {
      name: '发行',
      number: 8,
      url: 'https://huanle.jobs.feishu.cn/index?category=6791702736615393549',
    },
    {
      name: '全部',
      number: 0,
      url: 'https://huanle.jobs.feishu.cn/index',
    },
  ]
  const gameList = [
    'https://tc-cdn.123u.com/official-website/recruit/yxs.png',
    'https://tc-cdn.123u.com/official-website/recruit/dn.png',
    'https://tc-cdn.123u.com/official-website/recruit/jjsg.png',
    'https://tc-cdn.123u.com/official-website/recruit/ms.png',
    'https://tc-cdn.123u.com/official-website/recruit/ro.jpg',
  ]

  const workSpacePic = [
    {
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/recruit/bottombanner1.jpg',
      contentPosition: 'bottom-left',
    },
    {
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/recruit/bottombanner2.jpg',
      contentPosition: 'bottom-right',
    },
    {
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/recruit/bottombanner3.jpg',
      contentPosition: 'bottom-right',
    },
  ]
  const handleClickItem = useCallback((url) => {
    if (url !== undefined) {
      window.open(url)
    }
  })

  return (
    <div>
      <div className={styles.Banner}>
        <Banner bannerList={bannerList} autoplay />
      </div>
      <div className={styles.Hire}>
        <div className={styles.HireTitle}>社会招聘</div>
        <p></p>
        <div className={styles.JobList}>
          {hireList.map((e) => (
            <div className={styles.JobContainer} key={e.name}>
              <div className={styles.JobLeft}>
                <div className={styles.JobName}>{e.name}</div>
                <div className={styles.JobNumber}>{e.number}个职位正在招聘</div>

                <div
                  className={styles.Btn}
                  onClick={handleClickItem.bind(this, e.url)}
                >
                  <button type="button">
                    {e.number !== 0 ? `共${e.number}个职位` : '查 看'}
                  </button>
                </div>
              </div>
              <div className={styles.JobRight}>
                <div className={styles.MobileBtn}>
                  <button
                    type="button"
                    onClick={handleClickItem.bind(this, e.url)}
                  >
                    查 看
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className={styles.ShowAllJob}>
            <button
              type="button"
              onClick={handleClickItem.bind(
                this,
                'https://huanle.jobs.feishu.cn/index'
              )}
            >
              查看全部职位
            </button>
          </div>
        </div>
      </div>
      <div className={styles.WhoAmI} id="aboutUs">
        <div className={styles.WhoAmITitle}>我们是谁</div>
        <p></p>
        <div className={styles.WhoAmIContent}>
          -极度热爱：对创作游戏有一种执念，极度渴望，愿意全身心投入，是解决所有问题的源动力；
          <br />
          -极客精神：追求极致，是宁愿做让100人尖叫，也不做让10000人满意的作品；
          <br />
          -游戏狂：对游戏超级有爱、有激情，至少某方面有天赋，与众不同。
          <br />
          <div className={styles.WhoAmIImgWrap}>
            <img
              src="https://tc-cdn.123u.com/official-website/recruit/who_we_are.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className={styles.Games}>
        <div className={styles.GameTitle}>欢乐游戏</div>
        <p></p>
        {/* <GameList list={gameList} /> */}
        <div className={styles.GameImages}>
          {gameList.map((game) => (
            <img src={game} alt="" />
          ))}
        </div>
      </div>
      <div className={styles.WorkSpace}>
        <div className={styles.WorkSpaceTitle}>工作在这里</div>
        <p></p>
        <Banner bannerList={workSpacePic} autoplay loop />
      </div>
    </div>
  )
}

export default React.memo(SocialRecruit)
