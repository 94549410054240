import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './style.module.scss'
import Banner from './Banner'
import TimeLine from './TimeLine'
import Culture from './Culture'
import Mogul from './Mogul'
import MogulMob from './MogulMob'

export default function Company() {
  // 跳转页面指定位置

  const Section1 = useCallback(() => {
    const banners = [
      {
        id: 1,
        img:
          'https://tc-cdn.123u.com/official-website/company/company_banner2.png',
        titleEn: 'About Huanle Entertainment',
        title: '关于欢乐互娱',
        desc:
          '欢乐互娱，是一家具有全球游戏研发和发行运营经验的高新技术企业。2013年出品页游爆品《街机三国》，月流水破亿，持续运营8年；2017年出品手游爆品《龙之谷》，总流水突破40亿，海外10+个地区发行；2019年《英雄杀》小游戏DAU突破100万，收入TOP3；2020年研发3年的《仙境传说RO-爱如初见》上线韩服、日服、北美，总流水10亿+。公司极其擅长动作游戏品类及MMO、RPG游戏品类，有着丰富的研发经验和深厚的人才储备。欢乐产品理念：“宁做100人尖叫的产品，不做1万人叫好的产品”。欢乐出品，必属精品。',
      },
    ]

    return (
      <section id="banner">
        <Banner banners={banners}></Banner>
      </section>
    )
  }, [])

  const Section2 = useCallback(() => {
    const years = [
      {
        year: '2021',
        block1: [
          {
            month: '10月',
            text: '《英雄杀》成为棋牌游戏外DAU TOP1，内购流水增长破40%',
          },
        ],
        block2: [
          {
            month: '6月',
            text: '《Ragnarock Origin》正式运营，进入谷歌及IOS免费榜TOP1',
          },
        ],
      },
      {
        year: '2020',
        block1: [
          {
            month: '8月',
            text:
              '《英雄杀小游戏》注册用户突破5000万，并作为“小游戏商业化”成功案例，参与微信公开课小游戏专场分享。',
          },
          {
            month: '7月',
            text: '《RO爱如初见》正式运营，进入谷歌畅销榜，IOS畅销榜前3。',
          },
        ],
        block2: [
          {
            month: '5月',
            text:
              '《美食大战老鼠2》第一次测试，丰富的世界观和传承经典的玩法，深受玩家喜爱。',
          },
        ],
      },
      {
        year: '2019',
        block1: [
          {
            month: '12月',
            text: '《英雄杀小游戏》，微信收入排行榜TOP3，开创小游戏新高度。',
          },
        ],
        block2: [
          {
            month: '9月',
            text: '《英雄杀小游戏》突破100万DAU。',
          },
        ],
      },
      {
        year: '2018',
        block1: [
          {
            month: '12月',
            text:
              '《龙之谷手游》海外版《Dragn Nest M》凭借在泰国和印度尼西亚的出色表现,荣获Google Play最佳对战游戏推荐。',
          },
          {
            month: '5月',
            text: '《龙之谷手游》进入海外收入榜TOP15。',
          },
        ],
        block2: [
          {
            month: '3月',
            text:
              '与骏梦游戏合作，经典IP改编手游《仙境传说 爱如初见》开始立项研发。',
          },
        ],
      },
      {
        year: '2017',
        block1: [
          {
            month: '2月',
            text: '《龙之谷手游》上线，腾讯最高评级产品，全球流水40亿+。',
          },
        ],
        block2: [
          {
            month: '2月',
            text: '《龙之谷手游》首月流水突破9亿。',
          },
        ],
      },
      {
        year: '2010-2016',
        block1: [
          {
            month: '2016',
            text:
              '和巨人网络合作的《街机三国手游》上线。《美食大战老鼠》注册用户破亿。',
          },
          {
            month: '2015',
            text: '首款自发产品《热血街霸3D》上线。',
          },
          {
            month: '2013',
            text: '《街机之王》上线腾讯QQ空间。',
          },
        ],
        block2: [
          {
            month: '2013',
            text:
              '横版格斗类网页游戏《街机三国》上线，极具创新的格斗玩法，绚丽的特效和极爽快的打击感使游戏一出来就备受关注，被称为第三代网页游戏。',
          },
          {
            month: '2010',
            text: '塔防网页游戏《美食大战老鼠》上线，深受休闲游戏用户喜爱。',
          },
        ],
      },
      {
        year: '2009',
        block1: [
          {
            month: '7月',
            text:
              '五个热血青年，怀揣着梦想，在一个居民楼里，创办欢乐互娱，欢乐互娱，欢乐从这里开始。',
          },
        ],
        block2: [],
      },
    ]
    return (
      <section
        className={`${styles.SectionContainer} ${styles.TimeLineContainer}`}
        id="timeline"
      >
        <div>
          <h3 className={styles.SectionTitle}>发展大事记</h3>
          <p></p>
        </div>
        <section>
          <TimeLine years={years}></TimeLine>
        </section>
      </section>
    )
  }, [])

  const Section3 = useCallback(() => {
    const content = [
      {
        img: 'https://tc-cdn.123u.com/official-website/company/culture1.png',
        title: '利众',
        titleEn: 'Helpfulness',
        desc: ['与人为善', '先成人，后达己', '乐于分享'],
      },
      {
        img: 'https://tc-cdn.123u.com/official-website/company/culture3.png',
        title: '开放包容',
        titleEn: 'Inclusiveness',
        desc: ['尊重不同 | 坦诚表达', '拥抱变化 | 鼓励尝试 | 庆祝失败'],
      },
      {
        img: 'https://tc-cdn.123u.com/official-website/company/culture4.png',
        title: '创新',
        titleEn: 'Innovation',
        desc: ['基于用户需求，创造新体验', '保有好奇心', '勇于探索，敢做想做'],
      },
      {
        img: 'https://tc-cdn.123u.com/official-website/company/culture5.png',
        title: '追求极致',
        titleEn: 'Perfection',
        desc: [
          '对用户体验，绝不苟且',
          '匠心精神，精益求精',
          '善于学习总结，不断自我超越',
        ],
      },
      {
        img: 'https://tc-cdn.123u.com/official-website/company/culture2.png',
        title: '团队合作',
        titleEn: 'Cooperation',
        desc: [
          '一个团队，一个目标，一条心',
          '团队赢，个人赢',
          '互相支持，共进退，全负责',
        ],
      },
    ]
    return (
      <section
        className={`${styles.SectionContainer} ${styles.SectionCulture}`}
        id="culture"
      >
        <div>
          <h3>欢乐文化</h3>
          <p></p>
          <div className={styles.SectionCultureText}>
            <h4>愿景 使命</h4>
            <p>
              我们的愿景是“成为创造极致体验，启发心智的全球顶尖的文化娱乐公司”；我们的使命是“让更多人发现人生的美好”；我们价值观“利众，开放包容，创新，追求极致，团队合作”；多年后，当人们谈到欢乐互娱时，收到过感动，受到过启发，收获过开心，是美好的！
            </p>
          </div>
          <div className={styles.SectionCultureText}>
            <h4>价值观</h4>
          </div>
          <Culture content={content}></Culture>
        </div>
      </section>
    )
  })

  const Section4 = useCallback(() => {
    const users = [
      {
        id: 1,
        img: 'https://tc-cdn.123u.com/official-website/company/Steven2.png',
        name: '时继江',
        profession: '欢乐互娱CEO',
        idea: '做时间的朋友，做长线精品游戏。以用户为中心，以创作者为本。',
        desc:
          '2009年创办欢乐互娱，全面负责公司战略规划、定位和管理。成功带领公司出品多个爆款游戏。内心坚定，有极强的使命感。',
      },
      {
        id: 2,
        img: 'https://tc-cdn.123u.com/official-website/company/weikun.png',
        name: '大黑',
        profession: '研发副总裁',
        idea: '相信热爱的力量，兴趣和乐趣是最好的老师。',
        desc:
          '入行17年，资深游戏制作人，擅长策略军团类游戏，2013年《啪啪三国》系列，国内IOS畅销榜第8，台湾IOS畅销榜第2，安卓第1，韩国IOS畅销榜第1。',
      },
      {
        id: 3,
        name: 'Stefanie',
        profession: 'COO',
        img: 'https://tc-cdn.123u.com/official-website/company/wangjiejie.png',
        idea: '和你一起，改变世界',
        desc:
          '资深游戏制作人、欢乐互娱优秀管理者，曾带领团队制作《街机手游》、《英雄杀小游戏》，自上线后荣登微信小游戏收入排行TOP3，创造DAU过百万的优秀佳绩。',
      },
      {
        id: 4,
        name: 'Allen Lee',
        profession: 'CDO',
        img: 'https://tc-cdn.123u.com/official-website/company/allen_lee.png',
        idea: '每天都过得与众不同，你的未来就会发生改变',
        desc: '21年研发经验，韩国顶级TA大师，曾任职腾讯天美。',
      },
    ]
    return (
      <section className={`${styles.SectionContainer}`} id="mogul">
        <div>
          <h3 className={styles.SectionTitle}>欢乐大咖</h3>
          <p></p>
          <div>
            <Mogul users={users}></Mogul>
            <MogulMob users={users}></MogulMob>
          </div>
        </div>
      </section>
    )
  })

  const Section5 = () => (
    <section
      className={`${styles.SectionContainer} ${styles.TimeLineMobContainer}`}
      id="timeline-mobile"
    >
      <div>
        <h3 className={styles.SectionTitle}>发展大事记</h3>
        <p></p>
        <div>
          <img
            className={`${styles.TimeLineBg}`}
            src="https://tc-cdn.123u.com/official-website/mobile/company/timeline_v2.png"
          ></img>
        </div>
      </div>
    </section>
  )

  return (
    <div className={styles.CompanyContainer}>
      <Section1></Section1>
      <Section2></Section2>
      <Section3></Section3>
      <Section4></Section4>
      <Section5></Section5>
    </div>
  )
}
