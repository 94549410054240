import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '@/store/reducer'
import { actionCreators } from '../store'

function useFetchDetail() {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const newsDetail = useSelector((state: AppState) => state.news.newsDetail)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(actionCreators.changeNewsDetail({ content: '' }))
    const fetchData = async () => {
      await dispatch(actionCreators.getNewsDetail({ id: Number(id) }))
      setLoading(false)
    }
    fetchData()
  }, [id, dispatch])

  return { newsDetail, loading }
}

export default useFetchDetail
