// import { exact } from 'prop-types'
// import { Redirect } from 'react-router'
import React from 'react'
import { Redirect } from 'react-router-dom'
import Layout from '@/components/Layout'
import Home from '@/pages/Home'
import SocialReruit from '@/pages/SocialRecruit'
import Product from '@/pages/Product'
import Company from '@/pages/Company'
import News from '@/pages/News'
import NewsDetail from '@/pages/News/NewsDetail'
import Contact from '@/pages/Contact'
import CampusRecruit from '@/pages/CampusRecruit'
import CampusLayout from '@/components/CampusLayout'
import HireLayout from '@/components/HireLayout'
import Presentation from '@/pages/Presentation'
import Protocol from '@/pages/Protocol'

export default [
  {
    path: '/protocol/:gameCode/:code',
    component: Protocol,
  },
  {
    path: '/campus',
    component: CampusLayout,
    routes: [
      {
        path: '/campus/campusRecruit',
        exact: true,
        component: CampusRecruit,
      },
      {
        path: '/campus/presentation',
        exact: true,
        component: Presentation,
      },
    ],
  },
  {
    path: '/hire',
    component: HireLayout,
    routes: [
      {
        path: '/hire/socialRecruit',
        exact: true,
        component: SocialReruit,
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    routes: [
      {
        path: '/',
        exact: true,
        render: (): JSX.Element => <Redirect to="/home" />,
      },
      {
        path: '/home',
        exact: true,
        component: Home,
      },
      {
        path: '/company',
        exact: true,
        component: Company,
      },
      {
        path: '/product',
        exact: true,
        component: Product,
      },
      {
        path: '/socialReruit',
        exact: true,
        component: SocialReruit,
      },
      {
        path: '/contact',
        exact: true,
        component: Contact,
      },
      {
        path: '/news',
        exact: true,
        component: News,
      },
      {
        path: '/news/:id',
        exact: true,
        component: NewsDetail,
      },
      // {
      //   path: '/CampusRecruit',
      //   exact: true,
      //   component: CampusRecruit,
      // },
      {
        path: '*',
        render: (): JSX.Element => <Redirect to="/home" />,
      },
    ],
  },
]
