import { combineReducers } from 'redux'
import { reducer as homeReducer } from '@/pages/Home/store'
import newsReducer from '@/pages/News/store/reducer'
import produce, { enableES5 } from 'immer'
import { StoreState } from './typings'

enableES5()
const defaultState: StoreState = {
  menuList: [
    {
      name: '公司',
      path: '/company',
      children: [
        {
          name: '公司简介',
          path: '/company#banner',
        },
        {
          name: '发展历程',
          path: '/company#timeline',
        },
        {
          name: '企业文化',
          path: '/company#culture',
        },
        {
          name: '欢乐大咖',
          path: '/company#mogul',
        },
      ],
    },
    {
      name: '联系我们',
      path: '/contact',
      children: [],
    },
  ],
}

const common = (state: StoreState = defaultState): StoreState =>
  produce(state, () => {})

const rootReducer = combineReducers({
  home: homeReducer,
  news: newsReducer,
  common,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
