import React, { memo } from 'react'
import { Spin } from 'antd'
import NewsHead from './components/NewsHead'
import NewsTab from './components/NewsTab'
import styles from './style.module.scss'
import useFetchDetail from './hooks/useFetchDetail'
import richTextStyle from './richTextStyle.module.scss'

function NewsDetail() {
  const { newsDetail, loading } = useFetchDetail()

  return (
    <>
      <NewsHead />
      <NewsTab />
      <Spin spinning={loading}>
        <div className={styles.NewsDetail}>
          <div className={styles.NewsDetailWrap}>
            <div className={styles.Title}>{newsDetail.title}</div>
            <div className={styles.Time}>{newsDetail.time}</div>
            <div
              className={richTextStyle.Body}
              dangerouslySetInnerHTML={{ __html: newsDetail.content }}
            />
          </div>
        </div>
      </Spin>
    </>
  )
}

export default memo(NewsDetail)
